import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { animateScroll } from 'react-scroll';
import { push, go } from 'connected-react-router';
import { pathOr } from 'ramda';

import componentBase from '../../../../../../src/lib/component-base';
import { dateFormatString } from '../../../../../../src/lib/date-time-format';
import { Current } from '../../../../redux';
import NewFlightAlert from './NewFlightAlert';
import { isBusinessUser } from '../../../../../../src/utils/isBusinessUser';
import { errorMessageForCode } from '../../../../../../src/lib/errors';

const {
  newFlightAlert,
  loadFlightSchedulesByCarrierFlightNumberAndDate,
  setAlertPhonePreference,
  setQueryError,
} = Current.actions;

const scrollToTop = () => {
  animateScroll.scrollToTop({
    duration: 500,
    delay: 0,
    smooth: true,
  });
};

@connect(state => ({
  alertPhonePreference: state.FlightAlerts.alertPhonePreference,
  newAlertError: state.FlightAlerts.newAlertError,
  newAlertLoaded: state.FlightAlerts.newAlertLoaded,
  newAlertLoading: state.FlightAlerts.newAlertLoading,
  newAlertSuccess: state.FlightAlerts.newAlertSuccess,
  flightSchedulesError: state.FlightAlerts.flightSchedulesError,
  flightSchedulesLoaded: state.FlightAlerts.flightSchedulesLoaded,
  flightSchedules: state.FlightAlerts.flightSchedulesResult,
}))
@componentBase('NewFlightAlertContainer')
export default class NewFlightAlertContainer extends Component {
  static propTypes = {
    alertPhonePreference: PropTypes.bool,
    error: PropTypes.object,
    dispatch: PropTypes.func,
    match: PropTypes.object,
    user: PropTypes.object,
    // flightSchedulesError: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    // flightSchedulesLoaded: PropTypes.bool,
    flightSchedules: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    newAlertError: PropTypes.string,
    newAlertLoading: PropTypes.bool,
    newAlertLoaded: PropTypes.bool,
    newAlertSuccess: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }

  state = {
    chosenSegment: this.getChosenSegment(),
    errorOnSubmit: false,
    unableToCreateAlert: '',
  }

  componentWillMount() {
    this.timer = null;
    const { user } = this.props;
    const {
      flightAlertsMonthlyCredits,
      flightAlertsReservedCredits,
    } = user;

    if (!isBusinessUser(user) && !flightAlertsMonthlyCredits && !flightAlertsReservedCredits) {
      this.context.toast.error('This service requires the use of credits', 'Error', false, 3);
      this.timer = setTimeout(() => {
        this.props.dispatch(push('/flight-alerts/home'));
      }, 1000);
    }
  }

  componentDidMount() {
    const {
      dispatch,
      error,
      flightSchedules,
      match,
    } = this.props;

    const defaultAlertPhonePreference = pathOr(false, ['context', 'currentUser', 'flightMonitorWirelessEnabled'], this);
    dispatch(setAlertPhonePreference(defaultAlertPhonePreference));

    dispatch(loadFlightSchedulesByCarrierFlightNumberAndDate(match.params));
    if (error) {
      dispatch(setQueryError(errorMessageForCode(error.code)));
      dispatch(push('/flight-alerts/home'));
    } else if (flightSchedules && flightSchedules.error === 'ERR_EMPTY_RESPONSE') {
      dispatch(setQueryError(errorMessageForCode('FLIGHT_ALERT_ERROR1')));
      dispatch(push('/flight-alerts/home'));
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      flightSchedules,
      newAlertError,
      newAlertLoaded,
      newAlertSuccess,
    } = nextProps;
    if (newAlertError) {
      this.setState({
        errorOnSubmit: true,
      });
    }

    const {
      dispatch,
      flightSchedules: prevFlightSchedules,
      match,
    } = this.props;

    // the first couple times this runs, this.props.flightSchedules is undefined, then null
    if (flightSchedules !== prevFlightSchedules) {
      this.setState({
        chosenSegment: this.getChosenSegment(flightSchedules),
      });
    }

    if (newAlertLoaded && newAlertSuccess) {
      this.context.reportUserEvent('ManageFlightAlert', 'Create');
      if (match.params.trackerReferral) {
        dispatch(go('/flight-alerts/home/tracker'));
      }
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  getChosenSegment(schedule) {
    if (schedule && schedule instanceof Array) {
      return schedule.filter(segment => (
        segment.departureAirportFsCode === this.props.match.params.departureAirport &&
        segment.arrivalAirportFsCode === this.props.match.params.arrivalAirport
      ))[0];
    }
    return null;
  }

  handleSubmit = (options) => {
    this.setState({
      errorOnSubmit: false,
      unableToCreateAlert: null,
    });

    this.props.dispatch(newFlightAlert(options))
      .then(() => this.props.dispatch(push('/flight-alerts/home')))
      .catch(() => {
        this.setState({
          errorOnSubmit: true,
          unableToCreateAlert: 'Unable to set up flight alert',
        });
        scrollToTop();
      });
  }

  togglePhoneAlerts = (bool) => {
    this.props.dispatch(setAlertPhonePreference(bool));
  }

  render() {
    // this.logger('props:', this.props);
    let searchDate = null;
    const { match } = this.props;
    const { params } = match;
    const { day, month, year } = params;
    if (day && month && year) {
      searchDate =
        moment().set({ month: month - 1, date: day, year })
          .format(dateFormatString(this.context.currentUser));
    } else {
      searchDate = moment.utc().format(dateFormatString(this.context.currentUser));
    }

    if (this.state.chosenSegment) {
      return (
        <div className='new-flight-alert-container'>
          <NewFlightAlert
            alertPhonePreference={this.props.alertPhonePreference}
            flightInfo={this.state.chosenSegment}
            params={params}
            handleSubmit={this.handleSubmit}
            creationSuccess={this.state.newAlertSuccess && this.props.newAlertLoaded}
            submitButtonDisabled={this.props.newAlertLoading}
            errorOnSubmit={this.state.errorOnSubmit}
            unableToCreateAlert={this.state.unableToCreateAlert}
            date={searchDate}
            setAlertPhonePreference={this.togglePhoneAlerts}
          />
        </div>
      );
    }

    return (
      <div />
    );
  }
}
