// @flow
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import planeImageOrange from '../../../static/images/plane-orange.svg';
import {
  dateFormatSelect,
  timeFormatSelect,
} from '../../../src/lib/date-time-format';
import { media } from '../../../src/lib/css-media';
import CommonSectionWrapper from '../CommonSectionWrapper/CommonSectionWrapper';
import TabsContent from '../TabsContent/TabsContent';
import { Colors } from '../../../src/constants/style-constants';
import type { ReportUserEvent } from '../../../modules/common-types';

const planeIconWidth = '38px';

type AirportType = {
  city: string,
  fs: string,
  name: string,
  state: string,
  country: string,
};

type TabTitlesProps = {
  date1: string,
  date2: string,
};

type Flight = {
  arrivalAirport: AirportType,
  arrivalTime: string,
  arrivalTime24: string,
  arrivalTimeAmPm: string,
  arrivalTimezone: string,
  departureAirport: AirportType,
  departureTime: string,
  departureTime24: string,
  departureTimeAmPm: string,
  departureTimezone: string,
  sortTime: string,
  url: string,
};

type OtherDay = {
  date1: string,
  date2: string,
  day: string,
  year: string,
  flights: Array<Flight>,
};

type TimeGroupProps = {
  time: string,
  timeSuffix: string,
};

type PlaneProps = {
  altText: string,
};

type panelHeaderProps = {
  day: string,
  date1: string,
  date2: string,
  year: string,
};

type AirportProps = {
  fs: string,
  city: string,
};

type User = {};

type Params = {
  carrier: string,
  day: string,
  flightId: string,
  flightNumber: string,
  month: string,
  year: string,
};

type OtherFlightsProps = {
  carrier?: string,
  flightNumber?: string,
  otherDays: Array<OtherDay>,
  params: Params,
  reportUserEvent: ReportUserEvent,
  subscriptionActive: boolean,
  title: string,
  user: User,
};

const LinkTableRow = styled(Link)`
  display: flex;
  text-decoration: none;
  border-top: 1px solid ${Colors.gray4};
  justify-content: space-between;
  &:hover {
    background-color: ${Colors.fsTransparentOrange};
    cursor: pointer;
    text-decoration: none;
  }
`;

const Hyperlink = styled(Link)`
  color: ${Colors.fsOrange};
  text-decoration: underline;
`;

const TextHelper = styled.div`
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  color: ${Colors.white};
  margin: ${(props) => (props.margin ? props.margin : '5px auto')};
`;

const StyledInfo = styled.div`
  display: flex;
  color: ${Colors.white};
  text-align: center;
  min-width: 20%;
`;

const Airport = styled(StyledInfo)`
  flex-direction: column;
  justify-content: space-around;
  flex: ${(props) => (props.flex ? props.flex : '1')};
`;

const AirportContainer = (props: AirportProps): React.Element<any> => (
  <Airport>
    <TextHelper size="1.6rem" weight={600} margin="10px auto 1px">
      {props.fs}
    </TextHelper>
    <TextHelper size="1rem" weight={300} margin="1px auto 10px">
      {props.city}
    </TextHelper>
  </Airport>
);

const TimeGroupContainer = styled(StyledInfo)`
  ${media.tablet`
    display: none;
  `}
`;

const TimeSuffix = styled.span`
  margin-left: 5px;
  font-size: 1rem;
  font-weight: 300;
  color: ${Colors.white};
`;

const TimeGroup = (props: TimeGroupProps): React.Element<any> => (
  <TimeGroupContainer>
    <TextHelper size="1.6rem" weight={600} margin="10px auto">
      {props.time}
      <TimeSuffix>{props.timeSuffix}</TimeSuffix>
    </TextHelper>
  </TimeGroupContainer>
);

const PlaneIcon = styled.img.attrs({
  src: planeImageOrange,
  alt: (props) => (props && props.altText) || 'flight route',
})`
  width: ${planeIconWidth};
  height: 100%;
  pointer-events: none;
  max-height: 38px;
  margin-top: 10px;
`;

const Plane = (props: PlaneProps) => <PlaneIcon altText={props.altText} />;

const tableRow = (flight, user, clickHandler) => {
  const {
    arrivalTime,
    arrivalTimeAmPm,
    arrivalTime24,
    arrivalTimezone,
    departureTime,
    departureTimeAmPm,
    departureTime24,
    departureTimezone,
    url,
  } = flight;
  return (
    <LinkTableRow to={url} key={url} onClick={clickHandler}>
      <TimeGroup
        time={timeFormatSelect(user, departureTime24, departureTime)}
        timeSuffix={`${timeFormatSelect(
          user,
          ' ',
          `${departureTimeAmPm} `
        )}${departureTimezone}`}
      />
      <AirportContainer
        fs={flight.departureAirport.fs}
        city={flight.departureAirport.city}
      />
      <Plane altText="Plane Icon" />
      <AirportContainer
        fs={flight.arrivalAirport.fs}
        city={flight.arrivalAirport.city}
      />
      <TimeGroup
        time={timeFormatSelect(user, arrivalTime24, arrivalTime)}
        timeSuffix={`${timeFormatSelect(
          user,
          ' ',
          `${arrivalTimeAmPm} `
        )}${arrivalTimezone}`}
      />
    </LinkTableRow>
  );
};

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderText = styled(TextHelper)`
  min-width: 20%;
  text-align: center;
  ${media.tablet`
    flex: 1 1 auto;
    ${(props) => (props.hideMobile ? 'display: none;' : '')};
  `}
`;

const HeaderSeparator = styled.span`
  width: ${planeIconWidth};
`;

const Header = () => (
  <HeaderContainer>
    <HeaderText size="1rem" weight={400} margin="0" hideMobile>
      Departure Time
    </HeaderText>
    <HeaderText size="1rem" weight={400} margin="0">
      Origin
    </HeaderText>
    <HeaderSeparator />
    <HeaderText size="1rem" weight={400} margin="0">
      Destination
    </HeaderText>
    <HeaderText size="1rem" weight={400} margin="0" hideMobile>
      Arrival Time
    </HeaderText>
  </HeaderContainer>
);

const panelHeader = ({ day, date1, date2, year }: panelHeaderProps, user) => (
  <TextHelper size="1rem" weight={400} margin="10px 0">
    {`Flights for ${day}, ${dateFormatSelect(user, date1, date2)}-${year}`}
  </TextHelper>
);

const tableView = (otherDay, user, clickHandler) => (
  <React.Fragment>
    {panelHeader(otherDay, user)}
    <Header />
    {otherDay.flights.map((flight) => tableRow(flight, user, clickHandler))}
  </React.Fragment>
);

const NoFlightText = styled(TextHelper)`
  text-align: center;
`;

const noFlightsView = (otherDay, passedProps: OtherFlightsProps) => {
  const { carrier = '', flightNumber = '', user } = passedProps;

  const flightDescription = `${carrier}${flightNumber}`;

  return (
    <React.Fragment>
      {panelHeader(otherDay, user)}
      <NoFlightText size="1.3rem" weight={600} margin="30px auto 0">
        No Flight Information Available
      </NoFlightText>
      <NoFlightText size="1rem" weight={400} margin="10px auto 0">
        {`According to our data, ${
          flightDescription ? '' : 'this'
        } flight ${flightDescription} is not scheduled to fly on this date`}
      </NoFlightText>
    </React.Fragment>
  );
};

const tablePanel = (otherDay, props, clickHandler) =>
  otherDay.flights && otherDay.flights.length
    ? tableView(otherDay, props.user, clickHandler)
    : noFlightsView(otherDay, props);

const tabTitles = ({ date1, date2 }: TabTitlesProps, user) =>
  dateFormatSelect(user, date1, date2);

const tabsDescription = () => (
  <TextHelper size="1rem" weight={400} margin="0 5px 15px 2px">
    For flights prior to the results below, please use our{' '}
    <Hyperlink to="/historical-flight/search">
      Historical Flight Status
    </Hyperlink>{' '}
    feature.
  </TextHelper>
);

export const OtherFlights = (props: OtherFlightsProps) => {
  const {
    otherDays = [],
    params,
    reportUserEvent,
    subscriptionActive,
    title,
    user,
  } = props;

  const clickHandler = () => reportUserEvent('SelectOtherFlight');
  const titles = otherDays && otherDays.map((day) => tabTitles(day, user));
  const panels =
    otherDays && otherDays.map((day) => tablePanel(day, props, clickHandler));

  return (
    <CommonSectionWrapper subscriptionActive={subscriptionActive} title={title}>
      <TabsContent
        description={tabsDescription()}
        titles={titles}
        panels={panels}
        params={params}
        reportUserEvent={reportUserEvent}
      />
    </CommonSectionWrapper>
  );
};
