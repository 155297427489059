import { NAME } from './constants';
import * as t from './actionTypes';

export const loadFlight = (flightId, queryString) => async (dispatch, getState, client) => {
  dispatch({ type: t.LOAD_FLIGHT });

  try {
    const result = await client.get(`/${NAME}/${flightId}${queryString}`);

    return dispatch({
      type: t.LOAD_SUCCESS,
      result,
    });
  } catch (error) {
    return dispatch({
      type: t.LOAD_FAIL,
      error,
    });
  }
};
