import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import componentBase from '../../../../../src/lib/component-base';
import MarketingVideoCard from './children/MarketingVideoCard/MarketingVideoCard';
import SubscriptionHeader from '../../shared/SubscriptionHeader';
import SubscriptionFeatureChart from '../../shared/SubscriptionFeatureChart/SubscriptionFeatureChart';
import SubscriptionButton from '../../../../App/components/children/Header/SubscriptionButton';
import ImageCarousel from '../../../../../shared/components/ImageCarousel/components/container';
import NeedABusinessAccount from '../../../../../shared/components/NeedABusinessAccount/NeedABusinessAccount';
import { openTab } from '../../../../../src/utils/openTab';
import UpgradeSubscriptionModal from '../../../../App/components/children/Modals/UpgradeSubscriptionModal';
import { icons, featureChartTitles } from '../../../lib/constants';
import {
  endSpinner,
  getProfile,
  handlePlanSelection,
  upgradeSubscription,
  routerPush,
  startSpinner,
} from '../../../redux/actions';
import { userSubscriptionLevel, userSubscriptionActive } from '../../../redux/selectors';
import { addMetaCanonical } from '../../../../../shared/lib/meta-tags';

// Subscription carousel images
const standardSubMobile = '//assets.flightstats.com/v2/subscriptionCarousel/StandardSubscriptionCarouselImage-mobile.jpg';
const proSubMobile = '//assets.flightstats.com/v2/subscriptionCarousel/ProfessionalSubscriptionCarouselImage-mobile.jpg';
const standardSubMed = '//assets.flightstats.com/v2/subscriptionCarousel/StandardSubscriptionCarouselImage-medium.jpg';
const proSubMed = '//assets.flightstats.com/v2/subscriptionCarousel/ProfessionalSubscriptionCarouselImage-medium.jpg';
const standardSubLrg = '//assets.flightstats.com/v2/subscriptionCarousel/StandardSubscriptionCarouselImage-large.jpg';
const proSubLrg = '//assets.flightstats.com/v2/subscriptionCarousel/ProfessionalSubscriptionCarouselImage-large.jpg';


const {
  alertIcon,
  chartIcon,
  depArrIcon,
  freeIcon,
  historyIcon,
  premiumIcon,
  professionalSubscriptionIcon,
  standardSubscriptionIcon,
} = icons;

const depArrVideoSource = 'https://www.youtube.com/embed/y0tq-70LRJA?rel=0&amp;showinfo=0';
const flightAlertsVideoSource = 'https://www.youtube.com/embed/3e_cEGNDIWY?rel=0&amp;showinfo=0';
const historicalVideoSource = 'https://www.youtube.com/embed/gxKvvVfwxzw?rel=0&amp;showinfo=0';
const helpDeskLink = 'https://helpdesk.cirium.com/hc/en-us';

const PageEnum = {
  GENERAL: 'General',
  DEPARR: 'Airport Departures And Arrivals',
  ALERTS: 'Flight Alerts',
  HISTORICAL: 'Historical Search',
};

@connect(state => ({ user: state.Account.user }))
@componentBase('SubscriptionMarketing')
class SubscriptionMarketing extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    location: PropTypes.object,
    subscriptionType: PropTypes.string,
    userAgent: PropTypes.object,
    user: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      upgradeSubscriptionModalOpen: false,
    };
    this.handleModalToggle = this.handleModalToggle.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    if (location) {
      const { hash } = location;
      if (hash) {
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) element.scrollIntoView();
        }, 0);
      }
    }
  }

  getMetaTags = () => {
    let title = 'FlightStats - Subscribe Today';
    let description = 'FlightStats offers 2 levels of monthly subscription and turns on advanced features such as flight alerts, historical flight status and the advanced airport departures and arrivals.';
    const keywords = ['FlightStats Subscription', 'subscription features', 'flight alerts', 'airport departures and arrivals', 'historical flight status', 'subscribe today', 'professional subscription', 'standard subscription'];
    if (this.isSpecifiedPageEnum(PageEnum.ALERTS)) {
      // return 'Flight Alerts is a Subscription Feature';
      title = 'Subscribe for FlightStats Flight Alerts';
      description = 'Let FlightStats monitor your flight for you. We will notify you via email or SMS when important changes have occurred. Advanced features allow you to customize your notifications so you receive the right message at the right time.';
      keywords.push('SMS alerts,email alerts,custom notifications');
    } else if (this.isSpecifiedPageEnum(PageEnum.DEPARR)) {
      // return 'Airport Departures and Arrivals is a Subscription Feature';
      title = 'Subscribe for FlightStats Airport Departures and Arrivals';
      description = 'See statistics and detailed flight listings for flights at a particular airport by subscribing to the FlightStats Airport Departures and Arrivals feature. Its advanced sorting and filtering capabilities give you the ability to see just the flights you are interested in.';
      keywords.push('advanced flight filters,departure airport status,arrival airport status');
    } else if (this.isSpecifiedPageEnum(PageEnum.HISTORICAL)) {
      // return 'Historical Flight Status is a Professional Subscription Feature';
      title = 'Subscribe for Historical Flight Status';
      description = 'Search for global flights back to 2006 using the FlightStats Historical Flight Status feature. Information includes Final Status, Delay Calculations, Gate and Runway Times, Equipment Type, Terminal, Gate, and Baggage, and Upline and Downline Segments.';
      keywords.push('historical flights,historical flight delays');
    }
    const flattenedKeywords = keywords.join(',');
    const metaWithoutCanonical = {
      title,
      meta: [
        { name: 'description', content: description },
        { name: 'keywords', content: flattenedKeywords },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
      ],
    };
    const { location } = this.props;
    const pathname = location && location.pathname;
    return addMetaCanonical(metaWithoutCanonical, pathname);
  }

  getHeaderText = () => {
    if (this.isSpecifiedPageEnum(PageEnum.ALERTS)) {
      return 'Flight Alerts is a Subscription Feature';
    } else if (this.isSpecifiedPageEnum(PageEnum.DEPARR)) {
      return 'Airport Departures and Arrivals is a Subscription Feature';
    } else if (this.isSpecifiedPageEnum(PageEnum.HISTORICAL)) {
      return 'Historical Flight Status is a Professional Subscription Feature';
    }

    return 'FlightStats Subscription';
  }

  getCurrentPageEnum = () => {
    const { subscriptionType } = this.props;

    return subscriptionType ? PageEnum[subscriptionType] : PageEnum.GENERAL;
  }

  setSelectedPlan = () => {
    const plan = this.isSpecifiedPageEnum(PageEnum.HISTORICAL) ? 'premium' : 'standard';
    this.props.dispatch(handlePlanSelection(plan));
  }

  subscribeButtonClicked = (value) => {
    this.setSelectedPlan();
    this.context.reportButtonPress('Subscribe', value);
  }

  isSpecifiedPageEnum = specificEnum => this.getCurrentPageEnum() === specificEnum;

  handleModalToggle = (modalStateKey, bool, currentOpenCart) => {
    // bool: true is open, false closed
    const newState = Object.assign({}, this.state);
    newState[modalStateKey] = bool;
    newState.currentOpenCart = currentOpenCart;
    this.setState(newState);
  }

  carouselImages = () => {
    if (this.props && this.props.userAgent) {
      const { isMobile, isTablet } = this.props.userAgent;
      if (isMobile) {
        return [{ url: standardSubMobile, gaValue: 'CarouselStandard' },
          { url: proSubMobile, gaValue: 'CarouselProfessional' }];
      }
      if (isTablet) {
        return [{ url: standardSubMed, gaValue: 'CarouselStandard' },
          { url: proSubMed, gaValue: 'CarouselProfessional' }];
      }
    }
    return [{ url: standardSubLrg, gaValue: 'CarouselStandard' },
      { url: proSubLrg, gaValue: 'CarouselProfessional' }];
  }

  renderDepArrVideoCard = () => (
    <MarketingVideoCard
      featureBullets={['Detailed flight listings show everything from status to equipment type',
        'Ability to control how the flight listings are sorted',
        'Visualizations and statistics showing how airports are performing at a glance',
        'Advanced filtering allowing you to focus only on the flights you are interested in']}
      featureClosing={null}
      featureHeading='The most powerful airport departure and arrival feature on the market. Features include:'
      featureIcon={depArrIcon}
      featureSummary={null}
      featureTitle='Airport Departures And Arrivals'
      videoSource={depArrVideoSource}
      index={0}
      subscriptionButton={this.renderSubscribeButton()}
    />
  );

  renderFlightAlertVideoCard = () => (
    <MarketingVideoCard
      featureBullets={['We will notify you via email or SMS when important changes have occurred',
        `Advanced features allow you to customize your notifications
                        so you receive the right message at the right time`,
        'Standard subscriptions include 25 alert rules per month',
        'Professional subscriptions include 250 alert rules per month']}
      featureClosing='Additional flight rules are available to purchase if needed.'
      featureHeading='Let FlightStats monitor your flight for you.'
      featureIcon={alertIcon}
      featureSummary={null}
      featureTitle='Flight Alerts'
      videoSource={flightAlertsVideoSource}
      index={1}
      subscriptionButton={this.renderSubscribeButton()}
    />
  );

  renderHistoricalFlightStatusVideoCard = () => (
    <MarketingVideoCard
      featureBullets={['Final Status',
        'Delay Calculations',
        'Gate and Runway Times',
        'Equipment Type',
        'Terminal, Gate, and Baggage',
        'Scheduled and Actual Flight Times',
        'Upline and Downline Segments',
        'Complete Event Timeline',
        'Codeshare Information']}
      featureClosing='Historical Flight Status is only available as part of our Professional Subscription. 250 unique flight lookups are included per month. Additional credits are available for purchase.'
      featureHeading='Do you need to look up a flight in the past? Our Historical Flight Status tool gives you just that. Search by flight number, route or airport. Information includes:'
      featureIcon={historyIcon}
      featureSummary={null}
      featureTitle='Historical Flight Status'
      videoSource={historicalVideoSource}
      index={2}
      subscriptionButton={this.renderSubscribeButton()}
    />
  );

  renderSubscriptionMatrix = () => {
    const { user } = this.props;
    const activeSubscription = userSubscriptionActive(user);
    const subscriptionLevel = userSubscriptionLevel(user);
    const headerText = activeSubscription ? 'Ready to Upgrade?' : 'Ready to Subscribe?';
    const proText = activeSubscription ? 'Professional ' : '';
    const chartHeaderText = `Still unsure if a subscription is right for you?
      Our chart will help you make the best choice.
      Compare our free services to our Standard and Professional subscriptions side by side to see exactly
      how you’ll benefit.`;
    const hideSubscribeButtonStyle = activeSubscription && subscriptionLevel >= 3 ? { display: 'none' } : {};
    return (
      <div>
        <SubscriptionFeatureChart
          chartHeaderText={chartHeaderText}
          chartTitleHeader='Feature Comparison'
          freeIcon={freeIcon}
          standardSubscriptionIcon={standardSubscriptionIcon}
          professionalSubscriptionIcon={professionalSubscriptionIcon}
          premiumIcon={premiumIcon}
          featureTitles={featureChartTitles}
          chartIcon={chartIcon}
          mobileSubscribeButton={this.renderSubscribeButton()}
          activeSubscription={activeSubscription}
          subscriptionLevel={subscriptionLevel}
          highlightedRow={this.getCurrentPageEnum()}
        />
        <div className='row subscription-closer' style={hideSubscribeButtonStyle}>
          <div className='hidden-xs col-sm-6'>
            <div>
              <h2>{headerText}</h2>
              <h5>
                Take control of your day-of-travel.
                Try a {proText}subscription today with no obligation.
                You won’t regret it.
              </h5>
              <h5 id='FAQ'>
                For more details on subscription, please read our&nbsp;
                <a
                  href={helpDeskLink}
                  onClick={e => openTab(helpDeskLink, e)}
                >
                  FAQ
                </a>
              </h5>
            </div>
          </div>
          <div className='hidden-xs col-sm-6'>
            {this.renderSubscribeButton()}
          </div>
        </div>
      </div>
    );
  }

  renderCarousel = () => {
    const activeSubscription = userSubscriptionActive(this.props.user);
    if (this.getCurrentPageEnum() === PageEnum.GENERAL) {
      return (
        <ImageCarousel images={this.carouselImages()} activeSubscription={activeSubscription} />
      );
    }

    return null;
  }

  renderAccountSectionBackground = child => (
    <div className='marketing-section-background'>
      {child || ''}
    </div>
  );

  renderCard = (specificEnum, renderFunc) => {
    if (this.isSpecifiedPageEnum(specificEnum)) {
      return (
        <div className='marketing-section-background-container'>
          {this.renderAccountSectionBackground(renderFunc())}
        </div>
      );
    }
    return null;
  }

  renderDepArrCard = () => this.renderCard(
    PageEnum.DEPARR,
    this.renderDepArrVideoCard,
  );

  renderAlertsCard = () => this.renderCard(
    PageEnum.ALERTS,
    this.renderFlightAlertVideoCard,
  );

  renderHistoricalCard = () => this.renderCard(
    PageEnum.HISTORICAL,
    this.renderHistoricalFlightStatusVideoCard,
  );

  renderSubscribeButton = () => (
    <SubscriptionButton
      className='marketing-go-premium-button'
      dispatch={this.props.dispatch}
      location={this.props.location}
      reportButtonPressValue={this.subscribeButtonClicked}
      user={this.props.user}
    />
  );

  render() {
    return (
      <div className='subscription-feature-info-container'>
        <Helmet {...this.getMetaTags()} />
        <SubscriptionHeader text={this.getHeaderText()} />
        {this.renderCarousel()}
        {this.renderDepArrCard()}
        {this.renderAlertsCard()}
        {this.renderHistoricalCard()}
        <div className='marketing-section-background-container' id='compare-subscriptions'>
          {this.renderAccountSectionBackground(this.renderSubscriptionMatrix())}
          <UpgradeSubscriptionModal
            modalOpen={this.state.upgradeSubscriptionModalOpen}
            closeModal={() => this.handleModalToggle('upgradeSubscriptionModalOpen', false)}
            upgradeReduxAction={upgradeSubscription}
            getProfileReduxAction={getProfile}
            routerPush={routerPush}
            startSpinner={startSpinner}
            endSpinner={endSpinner}
          />
        </div>
        <div className='marketing-section-background-container business'>
          <NeedABusinessAccount />
        </div>
      </div>
    );
  }
}

export default connect(state => ({ userAgent: state.App.userAgent }))(SubscriptionMarketing);
