import React from 'react';
import PropTypes from 'prop-types';
import { ifElse, pathOr } from 'ramda';
import debounce from 'debounce';
import { push } from 'connected-react-router';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import AppBar from 'material-ui/AppBar';
import FlatButton from 'material-ui/FlatButton';
import LinearProgress from 'material-ui/LinearProgress';
import MobileIconButton from 'material-ui/IconButton';
import MobileNavigationCloseIcon from 'material-ui/svg-icons/navigation/close';
import MobileMenuIcon from 'material-ui/svg-icons/navigation/menu';
import {
  anyFlightTrackerRoute,
  getComponentAdName,
  shouldAdStateChange,
} from '../../shared/utils';
import { Colors } from '../../../../../src/constants/style-constants';
import Icon from '../../../../../src/components/lib/Icon';
import FSFlatButton from '../../../../../src/components/lib/FSFlatButton';
import componentBase from '../../../../../src/lib/component-base';
import { getFSHost } from '../../../../../src/lib/hostname';
import { openTab } from '../../../../../src/utils/openTab';
import BannerAd from '../../../../../shared/components/BannerAd/BannerAd';
import * as ads from '../../../ads';
import SubscriptionButton from './SubscriptionButton';
import LogoLink from './children/LogoLink';
import AppBarChildren from './children/AppBarChildren';
import FSMenuItem from './children/FSMenuItem';
import MobileMenuNew from './children/MobileMenu/MobileMenuNew';

import FSbyCLogo from '../../../../../static/images/FSbyCLogo.png';
import linkedinIcon from '../../../../../static/images/social-follow/LinkedIn-Circle.svg';
import twitterIcon from '../../../../../static/images/social-follow/Twitter-Circle.svg';
import youtubeIcon from '../../../../../static/images/social-follow/YouTube-Circle.svg';

const supportLink = 'https://helpdesk.cirium.com/hc/en-us/categories/202631018-FlightStats-by-Cirium';

const hiddenHeaderPaths = [
  '/account/invoice',
];

const logoLinkAnchorClass = 'fs-header-logo';
const logoLinkImgClass = 'fs-header-logo';
const calcMenuVerticalOffset = (ad, shouldDisplay) => {
  const {
    isDesktop,
  } = ad && ad.props;
  if (!shouldDisplay) {
    return 110;
  }
  if (isDesktop || !shouldDisplay) {
    return 90;
  }
  return 161;
};

// overrides unwanted inline styles from material ui
const appBarStyle = {
  paddingLeft: 0,
  paddingRight: 0,
  margin: 0,
};

const appBarButtonStyle = {
  border: 0,
  position: 'absolute',
  marginTop: '0',
  marginRight: '0',
  marginLeft: '0',
  marginBottom: '0',
  padding: '0',
  top: '30px',
  left: '12px',
};

MenuItem.defaultProps = {
  style: {
    color: 'black',
  },
};

@componentBase('HeaderComponent')
export default class HeaderComponent extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    isDesktop: PropTypes.bool,
    location: PropTypes.object,
    loggedIn: PropTypes.bool,
    logout: PropTypes.func,
    mediaBreakpoint: PropTypes.object,
    parsedComponentName: PropTypes.string,
    reportButtonPress: PropTypes.func,
    user: PropTypes.object,
    isLoading: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.menuItemClickHandler = this.menuItemClickHandler.bind(this);
    this.onNavItemSelected = this.onNavItemSelected.bind(this);
    this.onHamburgerTap = this.onHamburgerTap.bind(this);
    const shouldNotDisplayAds = pathOr(false, ['user', 'subscriptionActive'], this.props);
    this.state = {
      visibleMenu: '',
      menuOpen: false,
      shouldNotDisplayAds,
      shouldUpdateAdsException: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const shouldNotDisplayAds = shouldAdStateChange(this.props, nextProps);
    const shouldUpdateAdsException = anyFlightTrackerRoute(this.props, nextProps);
    this.setState({
      shouldNotDisplayAds,
      shouldUpdateAdsException: !shouldNotDisplayAds && shouldUpdateAdsException,
    });
  }

  onHamburgerTap() {
    this.setState({ menuOpen: !this.state.menuOpen });
    this.context.reportButtonPress('Hamburger');
  }

  onNavItemSelected() {
    this.setState({ menuOpen: false });
  }

  onTimePerformanceMenuItems = () => [
    this.createMenuItem({
      key: 'otp-menu-item-1',
      primaryText: 'On-Time Performance Reports',
      linkTo: 'https://www.cirium.com/resources/on-time-performance/?cmpid=ilc%7Cotp%7C202401_glob_airline-airports%7Con-time-performance_m',
      onClickTerm: 'Menu: On-Time Performance > OTP',
    }),
    this.createMenuItem({
      key: 'otp-menu-item-2',
      primaryText: 'On-Time Monthly Update',
      linkTo: 'https://www.cirium.com/resources/on-time-performance/?cmpid=ilc%7Cotp%7C202401_glob_airline-airports%7Con-time-performance_m',
      onClickTerm: 'Menu: On-Time Performance > OTMU',
    }),
    this.createMenuItem({
      key: 'otp-menu-item-3',
      primaryText: 'On-Time Performance Articles',
      linkTo: 'https://www.cirium.com/thoughtcloud-categories/on-time-performance/?cmpid=ilc%7Cairlines-airports%7CCI909-2022-on-time-review',
      onClickTerm: 'Menu: On-Time Performance > OTPA',
    }),
  ];

  getLogoLinkMobileClass = mobile => (mobile ? ' mobile' : '')

  hideHeader = (pathname) => {
    const bool = hiddenHeaderPaths.reduce((prev, hiddenPath) => {
      if (prev) {
        return prev;
      }
      return pathname.includes(hiddenPath);
    }, false);
    return bool;
  }

  menuItemClickHandler = (path, action) => (
    (e) => {
      e && e.preventDefault();
      this.onNavItemSelected();
      this.context.reportButtonPress(action);
      this.props.dispatch(push(path));
    }
  )

  openTabHandler = (link, label) => (
    (e) => {
      openTab(link, e);
      this.context.reportButtonPress(label);
    }
  );

  hideMenu() {
    this.setState({ visibleMenu: '' });
  }

  showMenu(evt) {
    debounce(this.setState({ visibleMenu: evt.currentTarget.id }), 200);
  }

  handleResize = () => this.forceUpdate();

  logout = async () => {
    const { logout, reportButtonPress } = this.props;
    await logout();
    this.onNavItemSelected();
    reportButtonPress('Logout');
  };

  reportButtonPressValue = (value) => {
    this.context.reportButtonPress('HeaderSubscriptionButton', value);
  }

  bigBlueAccountButton = () => {
    if (this.props.user) {
      return (
        <SubscriptionButton
          dispatch={this.props.dispatch}
          location={this.props.location}
          user={this.props.user}
          reportButtonPressValue={(value) => {
            this.onNavItemSelected();
            this.reportButtonPressValue(value);
          }}
          style={FSFlatButton.style({ marginRight: '10px' })}
          wrapperStyle={{ display: 'inline-block' }}
        />
      );
    }

    return (
      <FSFlatButton
        label='CREATE ACCOUNT'
        style={FSFlatButton.style({ marginRight: '10px' })}
        backgroundColor={Colors.blue6}
        hoverColor={Colors.blue8}
        className='signup-button'
        onClick={this.menuItemClickHandler('/register/plan', 'SignUp')}
        secondary
      />
    );
  }

  createMenuItem = item => (
    <FSMenuItem
      {...item}
      key={item.key}
      openTabHandler={this.openTabHandler}
      menuItemClickHandler={
        item.linkTo.includes('http') ?
          () => null :
        this.menuItemClickHandler
      }
    />
  );

  createHeaderNavItem = (id, title, menu, additionalClass) => (
    <span
      key={id}
      className={`nav-item ${additionalClass || ''} ${id}-header-nav-item`}
      onMouseEnter={this.showMenu}
      onMouseLeave={this.hideMenu}
      id={id}
    >
      {title}
      {menu}
    </span>
  )

  subscriptionActive = () => (this.props.user && this.props.user.subscriptionActive);
  proSubscription = () => (this.subscriptionActive() && this.props.user.subscriptionLevel >= 3);

  flightsMenuItems = () => [
    this.createMenuItem({
      key: 'flights-menu-item-1',
      primaryText: 'Flight Tracker',
      linkTo: `${getFSHost()}/flight-tracker/search`,
      onClickTerm: 'Menu: Flights > Flight Tracker',
    }),
    this.createMenuItem({
      key: 'flights-menu-item-2',
      primaryText: 'Historical Flight Status',
      linkTo: '/historical-flight/search',
      showLock: !this.proSubscription(),
      onClickTerm: 'Menu: Flights > Historical',
    }),
    this.createMenuItem({
      key: 'flights-menu-item-3',
      primaryText: 'Alerts',
      linkTo: '/flight-alerts/home',
      showLock: !this.subscriptionActive(),
      onClickTerm: 'Menu: Flights > Alerts',
    }),
  ];

  airportsMenuItems = () => [
    this.createMenuItem({
      key: 'airports-menu-item-1',
      primaryText: 'Current Conditions',
      linkTo: '/airport-conditions/search',
      onClickTerm: 'Menu: Airports > Current Conditions',
    }),
    this.createMenuItem({
      key: 'airports-menu-item-2',
      primaryText: 'Departures and Arrivals',
      linkTo: '/airports/departing-arriving/search',
      showLock: !this.subscriptionActive(),
      onClickTerm: 'Menu: Airports > Departures and Arrivals',
    }),
    this.createMenuItem({
      key: 'airports-menu-item-3',
      primaryText: 'Airport Delays',
      linkTo: '/airport-delays',
      onClickTerm: 'Menu: Airports > Delays',
    }),
  ];

  resourcesMenuItems = () => [
    this.createMenuItem({
      key: 'resources-menu-item-2',
      primaryText: 'Support',
      linkTo: supportLink,
      onClickTab: true,
      onClickTerm: 'Menu: Resources > Support',
    }),
    this.createMenuItem({
      key: 'resources-menu-item-3',
      primaryText: 'Developer Studio',
      linkTo: 'https://developer.cirium.com/?inctmp=flightstats-resources-menu',
      onClickTerm: 'Menu: Resources > Developer Studio',
    }),
    this.createMenuItem({
      key: 'resources-menu-item-4',
      primaryText: 'Careers',
      linkTo: 'https://relx.wd3.myworkdayjobs.com/ciriumcareers',
      onClickTerm: 'Menu: Resources > Careers',
    }),
    this.createMenuItem({
      key: 'resources-menu-item-5',
      primaryText: 'Contact',
      linkTo: 'https://www.cirium.com/contact-us/?cmpid=ILC%7CCIR%7CCIR-flightstats-contact',
      onClickTerm: 'Menu: Resources > Contact',
    }),
  ];

  ciriumMenuItems = () => [
    this.createMenuItem({
      key: 'cirium-menu-item-1',
      primaryText: 'About',
      linkTo: 'https://www.cirium.com/about/',
      onClickTerm: 'Menu: Cirium > About',
    }),
    this.createMenuItem({
      key: 'cirium-menu-item-2',
      primaryText: 'Products',
      linkTo: 'https://www.cirium.com/products/',
      onClickTerm: 'Menu: Cirium > Products',
    }),
    this.createMenuItem({
      key: 'cirium-menu-item-3',
      primaryText: 'Industries',
      linkTo: 'https://www.cirium.com/industry-solutions/',
      onClickTerm: 'Menu: Cirium > Industries',
    }),
    this.createMenuItem({
      key: 'cirium-menu-item-4',
      primaryText: 'Data Innovation',
      linkTo: 'https://www.cirium.com/data-innovation/',
      onClickTerm: 'Menu: Cirium > Data Innovation',
    }),
    this.createMenuItem({
      key: 'cirium-menu-item-5',
      primaryText: 'Pro Services',
      linkTo: 'https://www.cirium.com/cirium-pro/',
      onClickTerm: 'Menu: Cirium > Pro Services',
    }),
  ];

  myFlightStatsMenuItems = () => [
    this.createMenuItem({
      key: 'my-flightstats-menu-item-1',
      primaryText: 'My Alerts',
      linkTo: '/flight-alerts/home',
      showLock: !this.subscriptionActive(),
      onClickTerm: 'Menu: My FlightStats > My Alerts',
    }),
    this.createMenuItem({
      key: 'my-flightstats-menu-item-2',
      primaryText: 'My Account',
      linkTo: '/my-account/profile',
      onClickTerm: 'Menu: My FlightStats > My Account',
    }),
  ];

  accountMenuItems = () => {
    if (this.props.user) {
      return [
        this.createMenuItem({
          key: 'account-menu-item-1',
          primaryText: 'Logout',
          linkTo: '/account/login',
          onClickTerm: 'Logout',
          onClick: ((e) => {
            e.preventDefault();
            this.onNavItemSelected();
            this.logout();
          }),
        }),
        this.createMenuItem({
          key: 'account-menu-item-2',
          primaryText: 'My Account',
          linkTo: '/my-account/profile',
          onClickTerm: 'MyAccount',
        }),
        this.createMenuItem({
          key: 'account-menu-item-3',
          primaryText: 'My Alerts',
          linkTo: '/flight-alerts/home',
          showLock: !this.subscriptionActive(),
          onClickTerm: 'Search',
        }),
        <MenuItem
          key='account-menu-item-4'
          className='menu-item'
          style={{ backgroundColor: 'transparent' }}
          innerDivStyle={{
            color: '#FFFFFF',
            backgroundColor: '#81A2CA',
            margin: '19px',
            borderRadius: '3px',
            paddingLeft: '63px',
            paddingRight: '0',
            fontSize: '20px',
          }}
        >
          {this.bigBlueAccountButton()}
        </MenuItem>,
      ];
    }

    return [
      this.createMenuItem({
        key: 'account-menu-item-1',
        primaryText: 'Login',
        linkTo: '/account/login',
        onClickTerm: 'Login',
      }),
      this.createMenuItem({
        key: 'account-menu-item-2',
        primaryText: 'Create Account',
        linkTo: '/register/plan',
        onClickTerm: 'SignUp',
      }),
    ];
  }

  isMobileScreen = () => {
    const [isMobile, value] = ['isMobile', 'value']
      .map(v => pathOr(false, ['mediaBreakpoint', v], this.props));
    return isMobile || (value > 0 && value < 769);
  }

  socialOnClick = (e, social) => {
    this.context.reportSendInteraction('social', social);
    openTab(social.socialTarget, e);
  }

  mediaIcon = (icon, link, socialNetwork, action) => {
    const social = {
      socialNetwork,
      socialAction: action || 'follow',
      socialTarget: link,
    };

    return (
      <a href={link} onClick={e => this.socialOnClick(e, social)}>
        <Icon src={icon} />
      </a>
    );
  };

  followIcons = () => (
    <div className='social-follow-container'>
      Follow us on
      <div className='icons-container'>
        {this.mediaIcon(twitterIcon, 'https://www.twitter.com/cirium', 'Twitter')}
        {this.mediaIcon(linkedinIcon, 'https://www.linkedin.com/company/cirium', 'LinkedIn')}
        {this.mediaIcon(youtubeIcon, 'https://www.youtube.com/c/Cirium', 'YouTube', 'subscribe')}
      </div>
    </div>
  );

  render() {
    const mobile = this.isMobileScreen();
    const {
      isDesktop,
      isLoading,
      location = {},
      parsedComponentName,
      user,
    } = this.props;
    const {
      menuOpen,
      shouldNotDisplayAds,
      shouldUpdateAdsException,
      visibleMenu,
    } = this.state;
    const bigBlueAccountButton = this.bigBlueAccountButton();
    let topRightAdditionalSpace = null;

    let leftNav = null;
    const subscriptionActive = pathOr(false, ['user', 'subscriptionActive'], this.props);
    const { pathname } = location;
    const adComponentName = getComponentAdName(parsedComponentName, pathname);
    const headerAds = ads[adComponentName] || {};
    const { top, topMobile } = headerAds;
    const shouldDisplay = !shouldNotDisplayAds && headerAds;
    const ad = shouldDisplay && (
      <BannerAd
        desktopAd={top}
        mobileAd={topMobile}
        shouldUpdateAdsException={shouldUpdateAdsException}
        subscriptionActive={subscriptionActive}
        topAd
        isDesktop={isDesktop}
      />
    );

    const headerNavArray = [
      <div
        key='header-nav-1'
        className='header-logo'
      >
        <LogoLink
          anchorClass={logoLinkAnchorClass}
          imgClass={logoLinkImgClass}
          linkTo='/'
          logo={FSbyCLogo}
          logoAdditionalOnClick={this.onNavItemSelected}
          mobileClass={this.getLogoLinkMobileClass(mobile)}
          reportButtonPress={this.context.reportButtonPress}
        />
      </div>,
    ];

    let topRightButtons = null;
    const menuVerticalOffset = calcMenuVerticalOffset(ad, shouldDisplay);
    if (mobile) {
      const isTablet = pathOr(false, ['isTablet'], this.mediaBreakpoint);
      leftNav = (
        <MobileMenuNew
          menuVerticalOffset={menuVerticalOffset}
          gotoHandler={this.context.goto}
          isTablet={isTablet}
          logoutHandler={this.logout}
          open={menuOpen}
          onNavItemSelected={this.onNavItemSelected}
          user={user}
        />
      );
    } else {
      const menuStyle = {
        position: 'absolute',
        top: '39px',
        right: null,
        backgroundColor: '#FFF',
        zIndex: 1002,
        lineHeight: '9px',
        padding: '18px 0',
        boxShadow: 'rgba(0,0,0, .118) 0px 3px 3px',
      };

      const listStyle = {
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'transparent',
      };

      const customWidths = {
        resources: '236px',
        onTimePerformance: '335px',
      };

      const parseMenuTag = str => `${str.toLowerCase().replace('my', 'my-')}-nav`;
      const parseMenuTitle = str => `${str.charAt(0).toUpperCase()}${str.slice(1)}`.replace('My', 'My ').replace('OnTime', 'On-Time ');
      const parseStyle = ifElse(
        (str, visible) => parseMenuTag(str) === visible,
        str => ({ minWidth: (customWidths[str] || '180px') }),
        () => ({ display: 'none' }),
      );
      const menuItems = [
        'flights',
        'airports',
        'onTimePerformance',
        'cirium',
        'resources',
        'myFlightStats',
      ]
        .map((str, index) => ({
          title: parseMenuTitle(str),
          tag: parseMenuTag(str),
          element: () => (
            <Menu
              key={`main-header-menu-${index + 1}`}
              className='main-header-menu'
              style={{
                ...menuStyle,
                ...parseStyle(str, visibleMenu),
              }}
              listStyle={listStyle}
            >
              {this[`${str}MenuItems`]()}
            </Menu>
          ),
        }));

      const headerNavItems = (
        <div
          key='header-nav-2'
          className='fs-header-nav'
        >
          {menuItems.map(({ tag, title, element }, index) => this.createHeaderNavItem(
            tag,
            title,
            element(),
            index === menuItems.length - 1 ? 'right-nav' : '',
          ))}
        </div>
      );

      headerNavArray.push(headerNavItems);
    }

    if (!mobile) {
      let iconElementRight = null;

      const flatButtonStyle = FSFlatButton.style({
        textTransform: 'none',
        minWidth: 0,
      });
      const labelStyle = {
        color: Colors.orange1,
        paddingLeft: 5,
        paddingRight: 3,
        letterSpacing: 2,
        fontWeight: 600,
        textTransform: 'none',
      };

      if (user) {
        iconElementRight = (
          <FlatButton
            className='logout-button'
            style={flatButtonStyle}
            labelStyle={labelStyle}
            label='Logout'
            onClick={this.logout}
          />
        );
      } else {
        iconElementRight = (
          <FlatButton
            className='login-button'
            labelStyle={labelStyle}
            label='Login'
            onClick={this.context.gotoHandler('/account/login', 'Login')}
            style={flatButtonStyle}
          />
        );
      }

      if (bigBlueAccountButton && iconElementRight) {
        topRightAdditionalSpace = true;
      }

      topRightButtons = (
        <div
          className={`top-right-btns ${topRightAdditionalSpace ? ' blue-btn' : ''}`}
          style={{ float: 'right', marginRight: '22px' }}
        >
          {this.followIcons()}
          <div style={{ float: 'right' }}>
            {bigBlueAccountButton}
            {iconElementRight}
          </div>
        </div>
      );
    }

    return (
      <header
        className={(mobile ? 'mobile' : '')}
      >
        {/* display: none is overwritten in CSS.
          * This prevents mismatch between server and client render -@ma
        */}
        <AppBar
          style={{
            ...appBarStyle,
            display: 'none',
          }}
          iconStyleLeft={appBarButtonStyle}
          titleStyle={{
            display: 'none',
          }}
          title={false/* force material-ui to make the unused element a div instead of h1 */}
          className='fs-header'
          showMenuIconButton={mobile && !this.hideHeader(pathname)}
          iconElementLeft={
            <MobileIconButton>
              {menuOpen ? <MobileNavigationCloseIcon /> : <MobileMenuIcon />}
            </MobileIconButton>
          }
          onLeftIconButtonClick={this.onHamburgerTap}
        >
          <AppBarChildren
            ad={ad}
            headerNavArray={headerNavArray}
            hideHeader={this.hideHeader}
            logoLinkAnchorClass={logoLinkAnchorClass}
            logoAdditionalOnClick={this.onNavItemSelected}
            imgClass={logoLinkImgClass}
            logo={FSbyCLogo}
            mobileClass={this.getLogoLinkMobileClass(mobile)}
            pathName={pathname}
            reportButtonPress={this.context.reportButtonPress}
            subscriptionActive={subscriptionActive}
            topRightButtons={topRightButtons}
          />
        </AppBar>
        {leftNav}
        {isLoading ? <LinearProgress mode='indeterminate' color='#FAA718' style={{ backgroundColor: 'rgba(97, 78, 45, 1.0)' }} /> : null}
      </header>
    );
  }
}
