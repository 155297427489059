import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createMiddleware from './middleware/clientMiddleware';
import clientResponseMiddleware from './middleware/clientResponseMiddleware';
import reducers from './modules/reducer';
import { serverHelper } from './async-redux';

export default function createCombinedStore(client, data, history) {
  const combinedReducers = connectRouter(history)(combineReducers(reducers));
  const { App } = data;
  client.setCSRFToken(App.csrf);

  const middleware = [
    thunk.withExtraArgument(client),
    createMiddleware(client),
    clientResponseMiddleware(),
    routerMiddleware(history),
  ];

  let finalCreateStore;
  if ((__DEVELOPMENT__ || __STAGING__) && __CLIENT__ && __DEVTOOLS__) {
    /* eslint-disable no-underscore-dangle */
    const reduxDevTools = (
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
      window.__REDUX_DEVTOOLS_EXTENSION__()
    ) || (f => f);
    /* eslint-enable */

    finalCreateStore = compose(
      applyMiddleware(...middleware),
      reduxDevTools,
    )(createStore);
  } else {
    finalCreateStore = compose(
      serverHelper,
      applyMiddleware(...middleware),
    )(createStore);
  }

  const store = finalCreateStore(combinedReducers, data);

  if (__DEVELOPMENT__ && module.hot) {
    module.hot.accept('./modules/reducer', () => {
      store.replaceReducer(require('./modules/reducer'));
    });
  }

  return store;
}
