import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectFilterAsync } from '@flightstats/component-lib';
import componentBase from '../../../../src/lib/component-base';
import AccountError from '../MyAccount/children/children/AccountError';
import { isValidEmail, isValidPhoneNumber } from '../../../../src/helpers/Validators';
import carrierOptions from '../../../../shared/lib/carrierOptions';

@componentBase('AccountAlertPreferences')
export default class AccountAlertPreferences extends Component {
  static propTypes = {
    errors: PropTypes.object,
    user: PropTypes.object,
    resetAuthState: PropTypes.func,
  };

  static defaultProps = {
    isMyAccountPage: false,
    // primaryAlertEmail: null,
    errors: {},
    user: {
      flightMonitorEmailEnabled: null,
      flightMonitorWirelessEnabled: null,
    },
  };

  constructor(props) {
    super(props);
    this.flightMonitorEmail = null;
    this.flightMonitorWirelessEnabled = null;
    this.flightMonitorWirelessService = null;
    this.mobilePhoneNumber = null;
  }

  state = {
    phoneNumberValue: this.props.user.flightMonitorWirelessNumber0,
    validEmailStyle: null,
    validPhoneStyle: null,
    flightMonitorWirelessService: this.props.user.flightMonitorWirelessServiceCode0,
  };

  componentDidMount() {
    this.flightMonitorWirelessService = this.props.user.flightMonitorWirelessServiceCode0;
  }

  errorClass = shouldShow => (shouldShow ? 'selectError' : '');

  validateEmail = () => {
    this.props.resetAuthState();
    const emailInput = this.flightMonitorEmail;
    if (!isValidEmail(emailInput.value)) {
      this.setState({
        validEmailStyle: 'red',
      });
    } else {
      this.setState({
        validEmailStyle: null,
      });
    }
  };

  formatPhoneString = (input) => {
    if (input && input.length) {
      const sanitized = input.replace(/[\D\s]/g, '');
      return sanitized;
    }
    return '';
  };

  validatePhone = (event) => {
    this.props.resetAuthState();
    const {
      char,
      target,
      key,
      which,
    } = event;
    const value = target && target.value;
    const newValue = `${value}${char}`;
    const keyPressed = key || which;
    // don't run the formatter in certain situations, allowing for delete and arrow keys.
    const notArrowOrDeletionKeys = [8, 37, 38, 39, 40, 46].every(keyCode =>
      (keyCode !== keyPressed));
    if (notArrowOrDeletionKeys) {
      const formattedPhone = this.formatPhoneString(newValue);
      this.setState({
        phoneNumberValue: formattedPhone,
      });
    }

    if (!isValidPhoneNumber(newValue)) {
      this.setState({
        validPhoneStyle: 'red',
      });
      return false;
    }

    this.setState({
      validPhoneStyle: null,
    });
    const checkbox = this.flightMonitorWirelessEnabled;
    checkbox.checked = true;
  };

  handleServiceChange = (option) => {
    this.props.resetAuthState();
    this.setState({ flightMonitorWirelessService: option && option.value });
    this.flightMonitorWirelessService = option && option.value;
  }

  handleServiceRemove = () => {
    this.flightMonitorWirelessService = null;
    this.setState({ flightMonitorWirelessService: '' });
  }

  render() {
    const errors = this.props.errors || {};
    return (
      <div className='alert-preferences'>
        <div className='row'>
          <AccountError name='flightMonitorEmail' errorCode={errors.flightMonitorEmail} />
          <div className='col-xs-10 col-sm-9'>
            <h3 className='account-input-title'>Email</h3>
            <input
              type='text'
              ref={ref => (this.flightMonitorEmail = ref)}
              name='flightMonitorEmail'
              style={{ padding: '3px 3px 3px 5px', display: 'inline' }}
              defaultValue={this.props.user.flightMonitorEmailAddress0 || this.props.user.email}
              placeholder='name@domain.com'
              className={`account-input ${this.state.validEmailStyle} ${this.errorClass(errors.flightMonitorEmail)}`}
              onKeyUp={this.validateEmail}
            />
          </div>
          <div className='col-xs-2 col-sm-2 alert-preferences-default-checkbox-div'>
            <h3 className='account-input-title' style={{ textAlign: 'center' }}>Active</h3>
            <input
              type='checkbox'
              ref={ref => (this.flightMonitorEmailEnabled = ref)}
              name='flightMonitorEmailEnabled'
              className='alert-preferences-default-checkbox'
              defaultChecked={this.props.user.flightMonitorEmailEnabled}
            />
          </div>
        </div>
        <div className='row spaced'>
          <AccountError name='flightMonitorWireless' errorCode={errors.flightMonitorWireless} />
          <div className='col-xs-10 col-sm-9'>
            <h3 className='account-input-title'>Mobile Phone Number</h3>
            <input
              type='tel'
              ref={ref => (this.mobilePhoneNumber = ref)}
              name='mobilePhoneNumber'
              style={{ padding: '3px 3px 3px 5px', display: 'inline' }}
              value={this.state.phoneNumberValue || ''}
              placeholder='Example: 5551234567'
              onChange={this.validatePhone}
              onBlur={this.validatePhone}
              className={`account-input ${this.state.validPhoneStyle} ${this.errorClass(errors.flightMonitorWireless)}`}
            />
          </div>
          <div className='col-xs-2 col-sm-2 alert-preferences-default-checkbox-div'>
            <h3 className='account-input-title' style={{ textAlign: 'center' }}>Active</h3>
            <input
              type='checkbox'
              ref={ref => (this.flightMonitorWirelessEnabled = ref)}
              name='flightMonitorWirelessEnabled'
              className='alert-preferences-default-checkbox'
              defaultChecked={this.props.user.flightMonitorWirelessEnabled}
            />
          </div>
        </div>
        <div className='row spaced'>
          <AccountError name='flightMonitorWirelessService' errorCode={errors.flightMonitorWirelessService} />
          <div className='col-xs-10 col-sm-9'>
            <h3 className='account-input-title'>Carrier</h3>
            <SelectFilterAsync
              searchable
              clearable
              menuMaxHeight='263px'
              menuWidth='100%'
              placeholder='Select or Start Typing...'
              results={carrierOptions}
              value={this.state.flightMonitorWirelessService}
              handleRemoveSelection={this.handleServiceRemove}
              handleMenuSelection={this.handleServiceChange}
            />
          </div>
        </div>
      </div>
    );
  }
}
