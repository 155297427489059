import pathOr from 'ramda/src/pathOr';

export const getAirlineCodeSimpleSearch = obj => pathOr(null, ['fs'], obj) || pathOr('', ['iata'], obj);
export const getAirportCodeSimple = obj => pathOr(null, ['iata'], obj) || pathOr('', ['fs'], obj);
export const getAirportCodeValue = obj => pathOr(null, ['value', 'iata'], obj) || pathOr('', ['value', 'fs'], obj);
export const getAirportCodeProp = (prop, obj) => pathOr(null, [prop, 'iata'], obj) || pathOr('', [prop, 'fs'], obj);

export const istanbulAirportCodeCheck = (airportCode) => {
  if (airportCode === 'ISL') {
    return 'IST';
  } else if (airportCode === 'IST') {
    return 'ISL';
  }
  return airportCode;
};

export const istanbulAirportNameCheck = (airportName) => {
  if (airportName === 'Istanbul Ataturk Airport') {
    return 'Istanbul Airport';
  } else if (airportName === 'Istanbul Airport') {
    return 'Istanbul Ataturk Airport';
  }
  return airportName;
};
