import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { CommonSection } from '@flightstats/component-lib';
import componentBase from '../../src/lib/component-base';
import { addMetaCanonical } from '../../shared/lib/meta-tags';
import MobileAppCallout from '../../shared/components/MobileAppCallout/MobileAppCallout';
import HomepageSearch from './components/Search';
import FeaturesSection from './components/FeaturesSection';
// import COVID19Section from './components/COVID19Section';
// import CiriumSection from './components/CiriumSection';
import CiriumOTPSection from './components/CiriumOTPSection';
import AircraftEmissionsMonitor from './components/AircraftEmissionsMonitor';

const mapHeadingToPrettyTitle = {
  features: 'Features of the Site',
  mobile: 'Mobile Applications for the Active Traveler',
};

const sections = [
  {
    heading: 'features',
    component: FeaturesSection,
  },
  {
    heading: 'cirium-otp',
    hideBorder: true,
    component: CiriumOTPSection,
  },
  {
    heading: 'aircraft emissions',
    component: AircraftEmissionsMonitor,
    hideBorder: true,
  },
  {
    heading: 'mobile',
    component: MobileAppCallout,
  },
];

@componentBase('Homepage')
class Homepage extends Component {
  static propTypes = {
    location: PropTypes.object,
  }

  metaTags = () => {
    const title = 'FlightStats - Global Flight Status & Tracker, Airport Weather and Delays';
    const description = 'Track real-time flight status, departures and arrivals, airport delays, and airport information using FlightStats Global Flight Tracker from Cirium.';
    const keywords = 'flightstats,flight tracker,global flight status,flight status,airport weather,airport delays,flight alerts,departures,arrivals,on-time performance';

    const metaWithoutCanonical = {
      title,
      meta: [
        { name: 'keywords', content: keywords },
        { name: 'description', content: description },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
      ],
    };
    return addMetaCanonical(metaWithoutCanonical, '/');
  }

  render() {
    const {
      mediaBreakpoint = {},
      reportButtonPress,
    } = this.context;
    const { isMobile } = mediaBreakpoint;

    return (
      <div className='homepage-container'>
        <Helmet {...this.metaTags()} />
        <div className='homepage-section'>
          <CommonSection hideBorder>
            <HomepageSearch {...this.props} isMobile={isMobile} />
          </CommonSection>
        </div>
        {sections.map(section => (
          <div className='homepage-section' key={`section-${section.heading}`}>
            <CommonSection
              hideBorder={section.hideBorder}
              title={mapHeadingToPrettyTitle[section.heading]}
            >
              <section.component
                {...this.props}
                mediaBreakpoint={mediaBreakpoint}
                reportButtonPress={reportButtonPress}
              />
            </CommonSection>
          </div>
        ))}
      </div>
    );
  }
}

export default Homepage;
