import React from 'react';
import PropTypes from 'prop-types';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Dialog from 'material-ui/Dialog';
import { confirmable } from 'react-confirm';
import FSFlatButton from '../../../../src/components/lib/FSFlatButton';
import defaultContextTypes from '../../../../src/components/default-context-types';

@confirmable
export default class ConfirmModalDialog extends React.Component {
  static propTypes = {
    show: PropTypes.bool, // from confirmable. indicates if the dialog is shown or not.
    proceed: PropTypes.func, // from confirmable. call to close the dialog with promise resolved.
    cancel: PropTypes.func, // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func, // from confirmable. call to only close the dialog.
    confirmation: PropTypes.string, // arguments of your confirm function
    options: PropTypes.object, // arguments of your confirm function
    okLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    title: PropTypes.string,
    modal: PropTypes.bool,
  };
  static contextTypes = defaultContextTypes;
  static childContextTypes = defaultContextTypes;

  render() {
    const {
      okLabel = 'Ok',
      cancelLabel = 'Cancel',
      title,
      confirmation,
      show,
      proceed,
      dismiss,
      cancel,
      modal,
      options,
    } = this.props;

    const actions = [
      <FSFlatButton
        label={cancelLabel}
        secondary
        onClick={cancel}
        style={FSFlatButton.style({ backgroundColor: 'rgb(118, 177, 92)', color: '#fff', margin: '2px' })}
      />,
      <FSFlatButton
        id='confirm-modal-ok-button'
        label={okLabel}
        primary
        onClick={proceed}
        style={FSFlatButton.style({ backgroundColor: 'rgb(140, 56, 46)', color: '#fff', margin: '2px' })}
      />,
    ];

    return (
      <div>
        <MuiThemeProvider>
          <Dialog
            className='mui-dialog'
            contentClassName='mui-dialog-content'
            bodyStyle={{ color: 'rgba(7, 28, 53, 0.8)' }}
            bodyClassName={options.hideText ? 'confirm-text-hidden' : 'confirm-text-default'}
            title={title}
            actions={actions}
            modal={modal}
            open={show}
            onRequestClose={dismiss}
            options={options}
          >
            {confirmation}
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}
