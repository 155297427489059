import { push } from 'connected-react-router';
import * as t from './actionTypes';
import { errorMessageForCode } from '../../../../src/lib/errors';

const loading = type => ({
  type,
});

export const setQueryError = queryError => ({
  type: t.SET_QUERY_ERROR,
  queryError,
});

export const loadUserAlertHistory = () => async (dispatch, getStore, client) => {
  dispatch(loading(t.LOAD_USER_HISTORY));

  try {
    const result = await client.get('/flight-alerts-historical/summary');

    dispatch({
      type: t.LOAD_USER_HISTORY_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.LOAD_USER_HISTORY_FAIL,
      error,
    });
  }
};

export const deleteFlightAlert = ({ wmaId, flexId }, currentAlerts, index) =>
  async (dispatch, getStore, client) => {
    dispatch(loading(t.LOAD_DELETE_FLIGHT_ALERT));

    try {
      let result = null;
      const options = { wmaId, flexId };
      const response = await client.post('/flight-alerts/delete', { data: options });

      if (response.couldNotDestroyError && result.deactivatedResult) {
        const { couldNotDestroyError, deactivatedResult } = response;

        // wooops we tried to delete something we shouldn't delete,
        // we deactivated instead and let's let the user know
        const alertsClone = currentAlerts.concat();
        alertsClone[index] = deactivatedResult;
        result = {
          currentAlerts: alertsClone,
          couldNotDestroyError,
        };
      } else {
        // all good
        const nextState = currentAlerts.filter(alert => alert.flexId !== response.flexId);
        result = { currentAlerts: nextState };
      }

      dispatch({
        type: t.LOAD_DELETE_FLIGHT_ALERT_SUCCESS,
        result,
      });
    } catch (error) {
      dispatch({
        type: t.LOAD_DELETE_FLIGHT_ALERT_FAIL,
        error,
      });
    }
  };

export const loadFlightSchedulesByCarrierFlightNumberAndDate = params =>
  async (dispatch, getStore, client) => {
    dispatch(loading(t.LOAD_FLIGHT_SCHEDULES));

    try {
      const {
        carrier, flightNumber, year, month, day,
      } = params;
      const result = await client.get(`/flights/schedules/${carrier}/${flightNumber}/departing/${year}/${month}/${day}`);

      return dispatch({
        type: t.LOAD_FLIGHT_SCHEDULES_SUCCESS,
        result,
      });
    } catch (error) {
      return dispatch({
        type: t.LOAD_FLIGHT_SCHEDULES_FAIL,
        error,
      });
    }
  };

const upperCase = str => str && str.toUpperCase();

const createFlightScheduleCheckErrorMessage = ({
  carrier,
  flightNumber,
}) => (
  `Your flight alert cannot be set: We currently do not have a schedule for flight ${upperCase(carrier)}${upperCase(flightNumber)} on the selected date.`
);

export const checkFlightSchedulesByCarrierFlightNumberAndDate = params =>
  async (dispatch, getStore, client) => {
    dispatch(loading(t.CHECK_FLIGHT_SCHEDULES));

    try {
      const {
        carrier, flightNumber, year, month, day,
      } = params;
      const result = await client.get(`/flights/schedules/${carrier}/${flightNumber}/departing/${year}/${month}/${day}`);

      // there were no matching results - show the could not find flights based on your query error
      if (!result.length) {
        dispatch(setQueryError(errorMessageForCode('FLIGHT_ALERT_ERROR1')));
        dispatch(push('/flight-alerts/home'));
      }

      return dispatch({
        type: t.CHECK_FLIGHT_SCHEDULES_SUCCESS,
        result,
      });
    } catch (error) {
      dispatch(setQueryError(createFlightScheduleCheckErrorMessage(params)));
      dispatch({
        type: t.CHECK_FLIGHT_SCHEDULES_FAIL,
        error,
      });
    }
  };

export const newFlightAlert = ({
  userId,
  carrierFsCode,
  flightNumber,
  departureAirportFsCode,
  arrivalAirportFsCode,
  year,
  month,
  day,
  name,
  events,
  alternateDeliveryEmail,
  alternateDeliveryPhoneNumber,
  alternateDeliveryCarrier,
  timeUntilDep,
  timeUntilArr,
  departureTimeUTC,
  arrivalTimeUTC,
  shortText,
  sendEmail,
}) => async (dispatch, getStore, client) => {
  dispatch(loading(t.LOAD_NEW_ALERT));

  const options = {
    userId,
    carrierFsCode,
    flightNumber,
    departureAirportFsCode,
    arrivalAirportFsCode,
    year,
    month,
    day,
    name,
    events,
    timeUntilDep,
    timeUntilArr,
    departureTimeUTC,
    arrivalTimeUTC,
    shortText,
    sendEmail,
  };

  if (alternateDeliveryEmail != null) {
    options.alternateDeliveryEmail = alternateDeliveryEmail;
  }

  if (alternateDeliveryPhoneNumber != null) {
    options.alternateDeliveryPhoneNumber = alternateDeliveryPhoneNumber;
    options.alternateDeliveryCarrier = alternateDeliveryCarrier;
  }

  try {
    const result = await client.post('/flight-alerts/new', { data: options });
    dispatch({
      type: t.LOAD_NEW_ALERT_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.LOAD_NEW_ALERT_FAIL,
      error,
    });
  }
};

export const getCurrentFlightAlertsForUser = () => async (dispatch, getStore, client) => {
  dispatch(loading(t.LOAD_CURRENT));

  try {
    const result = await client.get('/flight-alerts/user/all-current');
    dispatch({
      type: t.LOAD_CURRENT_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.LOAD_CURRENT_FAIL,
      error,
    });
  }
};

export const setSortColumnAndOrder = (columnName, order) => ({
  type: t.SORT,
  sortColumn: columnName,
  sortOrder: order,
});

export const testAlert = (index, flexId, alertType) => async (dispatch, getStore, client) => {
  dispatch(loading(t.LOAD_TEST_ALERT));

  try {
    const options = { index, flexId, alertType };
    const result = await client.post('/flight-alerts/test-alert', { data: options });
    dispatch({
      type: t.LOAD_TEST_ALERT_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.LOAD_TEST_ALERT_FAIL,
      error,
    });
  }
};

export const deactivateFlightAlert = (wmaId, index, OGFlightAlerts = []) =>
  async (dispatch, getStore, client) => {
    dispatch(loading(t.LOAD_DEACTIVATE_ALERT));

    try {
      const options = { wmaId };
      const response = await client.post('/flight-alerts/deactivate', { data: options });
      const result = OGFlightAlerts.concat();
      result[index] = response;

      dispatch({
        type: t.LOAD_DEACTIVATE_SUCCESS,
        result,
      });
    } catch (error) {
      dispatch({
        type: t.LOAD_DEACTIVATE_FAIL,
        error,
      });
    }
  };

export const reactivateFlightAlert = (wmaId, index, OGFlightAlerts = []) =>
  async (dispatch, getStore, client) => {
    dispatch(loading(t.LOAD_REACTIVATE_ALERT));

    try {
      const options = { wmaId };
      const response = await client.post('/flight-alerts/reactivate', { data: options });
      const result = OGFlightAlerts.concat();
      result[index] = response;

      dispatch({
        type: t.LOAD_REACTIVATE_SUCCESS,
        result,
      });
    } catch (error) {
      dispatch({
        type: t.LOAD_REACTIVATE_FAIL,
        error,
      });
    }
  };

export const getCurrentServerDate = () => async (dispatch, getStore, client) => {
  dispatch(loading(t.LOAD_CURRENT_DATE));

  try {
    const result = await client.get('/utils/current-date');
    dispatch({
      type: t.LOAD_CURRENT_DATE_SUCCESS,
      result,
    });
  } catch (error) {
    dispatch({
      type: t.LOAD_CURRENT_DATE_FAIL,
      error,
    });
  }
};

export const errorCheckingSchedulesBool = bool => ({
  type: t.CHECKING_SCHEDULES_RESULTS_ERROR,
  bool,
});

export const setAlertPhonePreference = alertPhonePreference => ({
  type: t.SET_ALERT_PHONE_PREFERENCE,
  alertPhonePreference,
});

export const setSecondaryActionBool = bool => ({
  type: t.SET_SECONDARY_ACTION,
  bool,
});
